<template>
  <v-menu
    v-bind="$attrs"
    max-height="calc(100vh - 40px)"
    :min-width="minWidth"
    @input="onInput"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on">
        <v-btn
          v-testid="`dropdown-menu-btn-default-activator`"
          icon
          @click.stop.prevent="on.click"
          @mousedown.stop=""
        >
          <v-icon>{{ activatorIcon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list>
      <component
        :is="item.type || 'DropdownMenuItemDefault'"
        v-for="(item, index) in items"
        :key="index"
        v-testid="
          `dropdown-menu-item-${(item.props && item.props.id) || index}`
        "
        v-bind="item.props"
        @click="$emit('input', item.props)"
        v-on="item.listeners"
      />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    activatorIcon: {
      type: String,
      default: '$more_vert'
    },
    minWidth: {
      type: Number,
      default: 170
    }
  },
  methods: {
    onInput(value) {
      if (value) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    }
  }
};
</script>
